import { useSearchContext } from '@/contexts/search'
import { HStack, Spacer, Switch, Text } from '@chakra-ui/react'
import FiltersTags from './FiltersTags'
import SortDropdown from './SortDropdown'

export default function PropertiesListHeader({
  titleText,
  total,
  showMap,
  onShowMap,
  isMobile,
  InfoBtn = null
}) {
  const { sortKey, onSortChanged } = useSearchContext()

  return (
    <HStack minH={10}>
      <Text fontSize="sm" fontWeight="semibold">
        {titleText}
      </Text>
      {InfoBtn && <InfoBtn />}
      {!isMobile ? <FiltersTags /> : <Spacer />}

      {!isMobile && total > 0 && (
        <HStack>
          <Text fontSize="sm">Show Map</Text>
          <Switch isChecked={showMap} onChange={onShowMap} />
        </HStack>
      )}

      {isMobile && (
        <SortDropdown
          sortKey={sortKey}
          onChange={onSortChanged}
          variant="ghost"
        />
      )}
    </HStack>
  )
}
