import { isBrowser } from '@/lib/utils'
import React from 'react'
import { useIsomorphicLayoutEffect } from 'react-use'

/**
 * An utility hook that keeps track of the WordPress header height
 * `updateHeaderHeight` recalculates the header height and returns the latest value
 *
 * If the header is not fully visible it returns 0
 *
 * @returns [height, updateHeaderHeight]
 */
export function useTrackWordPressHeaderHeight() {
  const headerRef = React.useRef(
    isBrowser ? document.getElementById('header') : null
  )
  const [headerHeight, setHeaderHeight] = React.useState(0)

  // Figure out initial header height
  useIsomorphicLayoutEffect(() => {
    // Get the initial header height
    if (headerRef.current) {
      setHeaderHeight(headerRef.current.clientHeight)
    }
  }, [])

  const updateHeaderHeight = React.useCallback(() => {
    const height = getHeaderHeight(headerRef.current)
    if (height !== headerHeight) {
      setHeaderHeight(height)
    }

    return height
  }, [headerHeight])

  return [headerHeight, updateHeaderHeight]
}

// Returns the header height if the header is fully visible
function getHeaderHeight(el) {
  if (!el) return 0
  const relativeTop = el.getBoundingClientRect().top
  if (relativeTop < 0) return 0
  return el.clientHeight
}
