import React from 'react'

// Hide WordPress footer 🔨
export function useHideWordPressFooter(condition) {
  React.useEffect(() => {
    const footer = document.getElementsByTagName('footer')[0]
    const socket = document.getElementById('socket')
    if (!footer && !socket) return

    if (footer) hideShowElement(footer, condition)
    if (socket) hideShowElement(socket, condition)

    return () => {
      if (footer) hideShowElement(footer, false)
      if (socket) hideShowElement(socket, false)
    }
  }, [condition])
}

const hideShowElement = (el, condition) => {
  if (el) {
    if (condition) {
      el.style.display = 'none'
    } else {
      el.style.display = 'block'
    }
  }
}
