import { Button } from '@chakra-ui/react'
import React from 'react'
import { IoList, IoMap } from 'react-icons/io5'

const MobileMapViewButton = ({ showMapView, onClick, ...props }) => {
  const label = showMapView ? 'Show list' : 'Show map'
  const Icon = showMapView ? IoList : IoMap

  return (
    <Button
      zIndex={2}
      position="fixed"
      bottom="6"
      right="50%"
      transform="translate(50%,-50%)"
      rightIcon={<Icon />}
      rounded="md"
      size="sm"
      shadow="sm"
      color="white"
      bgColor="gray.900"
      _hover={{
        bgColor: 'gray.800'
      }}
      _active={{
        bgColor: 'gray.700'
      }}
      onClick={onClick}
      {...props}
    >
      {label}
    </Button>
  )
}

export default MobileMapViewButton
