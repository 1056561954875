import { useSettingContext } from '@/contexts/settings'
import { Box } from '@chakra-ui/react'
import GoogleMapReact from 'google-map-react'
import React from 'react'
export { fitBounds } from 'google-map-react'

export const Map = React.forwardRef(
  (
    {
      bounds = {},
      height = 400,
      width = '100%',
      zoomSetting,
      mapType,
      children,
      ...props
    },
    ref
  ) => {
    const { settings } = useSettingContext()
    const mapProps = {
      center: bounds.center,
      zoom: bounds.zoom ?? zoomSetting ?? 12
    }

    return (
      <Box ref={ref} w={width} height={height} {...props}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: settings.googleMapApiKey }}
          zoom={mapProps.zoom}
          center={mapProps.center}
          options={{
            mapTypeId: mapType ? mapType.toLowerCase() : 'roadmap',
            mapTypeControl: true,
            clickableIcons: false
          }}
        >
          {children}
        </GoogleMapReact>
      </Box>
    )
  }
)

Map.displayName = 'Map'

Map.PinMarker = React.forwardRef(function MapPinMarker(
  { name, color, onClick },
  ref
) {
  return (
    <Box
      ref={ref}
      role="button"
      title={name}
      boxSize="30px"
      borderRadius="50% 50% 50% 0"
      position="absolute"
      transform="rotate(-45deg)"
      left="50%"
      right="50%"
      margin="-20px 0 0 -20px"
      backgroundColor={color ?? 'wpMain.primary'}
      cursor="pointer"
      _after={{
        content: '""',
        width: '14px',
        height: '14px',
        margin: '8px 0 0 8px',
        background: 'gray.100',
        position: 'absolute',
        borderRadius: '50%'
      }}
      onClick={onClick}
    />
  )
})

/**
 *
 * @param {latLng} latLng[]
 * @returns
 */
export function getBounds(latLng) {
  if (!latLng?.length) return null

  const initial = {
    nw: {
      lat: latLng[0].lat,
      lng: latLng[0].lng
    },
    se: {
      lat: latLng[0].lat,
      lng: latLng[0].lng
    }
  }

  return latLng.reduce((acc, p) => {
    return {
      nw: {
        lat: acc.nw.lat > p.lat ? acc.nw.lat : p.lat,
        lng: acc.nw.lng < p.lng ? acc.nw.lng : p.lng
      },
      se: {
        lat: acc.se.lat < p.lat ? acc.se.lat : p.lat,
        lng: acc.se.lng > p.lng ? acc.se.lng : p.lng
      }
    }
  }, initial)
}
