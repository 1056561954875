import useIsVisible from '@/hooks/useIsVisible'
import {
  Box,
  Button,
  Center,
  Fade,
  SimpleGrid,
  Spinner
} from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'
import { useEffect, useRef } from 'react'
import PropertyCard from './PropertyCard'

const PropertiesCardList = ({
  properties,
  setSize,
  isRefreshing,
  hasMoreResults,
  isLoadingMore,
  showMap,
  EmptyResultsComp,
  favoriteIds,
  setFavoriteIds,
  favoritesProperties,
  setFavoritesProperties
}) => {
  const { t } = useTranslation('search')
  const ref = useRef()
  const isEndOfListVisible = useIsVisible(ref)

  const toggleFavorites = (property) => {
    const { name, slug, beds, baths, occu, images, id } = property
    const firstImage = images[0].full_url

    if (favoriteIds.includes(id)) {
      const newFavoriteIds = favoriteIds.filter((favorite) => favorite !== id)
      const newFavoritesProperties = favoritesProperties.filter(
        (property) => property.id !== id
      )
      setFavoriteIds(newFavoriteIds)
      setFavoritesProperties(newFavoritesProperties)
    } else {
      setFavoriteIds([...favoriteIds, id])
      setFavoritesProperties([
        ...favoritesProperties,
        {
          id,
          name,
          slug,
          beds,
          baths,
          occu,
          firstImage
        }
      ])
    }
  }

  useEffect(() => {
    if (!favoriteIds || !favoritesProperties) return
    localStorage.setItem('favoriteIds', JSON.stringify(favoriteIds))
    localStorage.setItem(
      'favoritesProperties',
      JSON.stringify(favoritesProperties)
    )
  }, [favoriteIds, favoritesProperties])

  useEffect(() => {
    if (isEndOfListVisible && !isRefreshing) {
      setSize((s) => s + 1)
    }
  }, [isEndOfListVisible, isRefreshing, setSize])

  const noResults = properties?.length === 0 && !isRefreshing && !hasMoreResults

  if (noResults) return <EmptyResultsComp />

  return (
    <Box>
      <SimpleGrid
        columns={{
          sm: showMap ? 1 : 1,
          md: showMap ? 1 : 2,
          lg: showMap ? 1 : 3,
          xl: showMap ? 2 : 4
        }}
        spacing={10}
        mt={2}
      >
        {properties.map((p, i) => (
          <PropertyCard
            key={p.slug}
            property={p}
            gridIndex={i}
            favorites={favoriteIds}
            toggleFavorites={toggleFavorites}
          />
        ))}
      </SimpleGrid>
      <Box ref={ref}>
        {isLoadingMore && (
          <Center p={10}>
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="wpMain.primary"
              size="xl"
            />
          </Center>
        )}
        {hasMoreResults && !isLoadingMore && (
          <Fade in={true} delay={1}>
            <Center p={10}>
              <Button onClick={() => setSize((s) => s + 1)}>
                {t('load-more')}
              </Button>
            </Center>
          </Fade>
        )}
      </Box>
    </Box>
  )
}

export default PropertiesCardList
