import ZeroRateMessageIcon from '@/icons/zero-rate.svg'
import { Box, Flex, Icon, Text } from '@chakra-ui/react'

const ZeroRateMessage = ({ message }) => {
  return (
    <Box bgColor="gray.100" borderRadius="5px" px="40px" py="30px" my="30px">
      <Flex gap="16px">
        <Icon boxSize="50px" as={ZeroRateMessageIcon} />
        <Flex flexDir="column">
          <Text fontSize="16px" color="gray.600">
            Booking tips
          </Text>
          <Text fontSize="20px" color="gray.800">
            A Guide to Booking Your Ideal Vacation Rental
          </Text>
        </Flex>
      </Flex>
      <Box
        overflow="auto"
        whiteSpace="normal"
        mt="24px"
        sx={{
          '*': {
            color: 'gray.600'
          },
          ul: {
            listStyleType: 'disc',
            paddingLeft: '20px'
          }
        }}
        dangerouslySetInnerHTML={{ __html: message }}
      ></Box>
    </Box>
  )
}

export default ZeroRateMessage
