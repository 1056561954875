import ArrowRight from '@/icons/arrow-right.svg'
import ArrowUp from '@/icons/arrow-up.svg'
import {
  Box,
  Button,
  Collapse,
  Icon,
  Stack,
  useDisclosure
} from '@chakra-ui/react'
import React from 'react'
import { useMeasure } from 'react-use'
import { PageDescription, PageTitle } from '../core/page'

const minDescHeight = 60

export default function PageSummary({ seo }) {
  const { isOpen, onToggle } = useDisclosure()
  const [ref, { height }] = useMeasure()

  const startingHeight = Math.min(Math.max(height, minDescHeight), 114)
  const displayShowMore = height > startingHeight

  return (
    <Stack maxW="1450" spacing={5} pb={displayShowMore ? 4 : 10}>
      <PageTitle>{seo.page_h1}</PageTitle>
      <Box>
        <Collapse in={isOpen} animateOpacity startingHeight={startingHeight}>
          <PageDescription ref={ref}>{seo.page_text}</PageDescription>
        </Collapse>
        {displayShowMore && (
          <Button
            onClick={onToggle}
            size="sm"
            my={4}
            variant="unstyled"
            _hover={{
              textDecoration: 'underline'
            }}
            rightIcon={
              isOpen ? (
                <Icon as={ArrowUp} boxSize={2} fill="gray.800" />
              ) : (
                <Icon as={ArrowRight} boxSize={2} fill="gray.800" />
              )
            }
          >
            {isOpen ? 'Show less' : 'Show more'}
          </Button>
        )}
      </Box>
    </Stack>
  )
}
