import { usePageContext } from '@/contexts/page'
import SearchContextProvider, { useSearchContext } from '@/contexts/search'
import SettingContextProvider from '@/contexts/settings'
import { useSignalContext } from '@/contexts/signal'
import { useSWRInfiniteMeta } from '@/hooks/useSWRInfiniteMeta'
import { getApi } from '@/lib/client/api'
import EmptyStateSearch from '@/public/images/empty_state_search.png'
import {
  Box,
  Button,
  Container,
  Flex,
  Stack,
  StackDivider,
  Text,
  useBoolean,
  useBreakpointValue
} from '@chakra-ui/react'
import { omit } from 'lodash'
import { useTranslation } from 'next-i18next'
import Head from 'next/head'
import Image from 'next/image'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'
import useSWRInfinite from 'swr/infinite'
import Breadcrumbs from '../core/Breadcrumbs'
import PageSummary from '../core/PageSummary'
import ZeroRateMessage from '../property/ZeroRateMessage'
import FiltersTags from '../search/FiltersTags'
import MobileMapViewButton from '../search/MobileMapViewButton'
import PropertiesCardList from '../search/PropertiesCardList'
import PropertiesListHeader from '../search/PropertiesListHeader'
import PropertiesMap from '../search/PropertiesMap'
import SearchBar from '../search/search-bar/SearchBar'
import SearchOverlay from '../search/search-bar/SearchOverlay'

const api = getApi()

const getSWRKey = (pageIndex, previousPageData, query) => {
  if (previousPageData && !previousPageData.next) {
    return null // reached the end
  }
  return ['/properties/search', query, pageIndex + 1] // SWR key
}

const EmptyResults = () => {
  const { onClearFilters } = useSearchContext()
  const { t } = useTranslation('search')

  return (
    <Box>
      <Image src={EmptyStateSearch} alt="no properties found" unoptimized />
      <Text fontSize="sm" color="gray.600">
        <Box as="span">{t('no-properties-available')} </Box>
        <Button
          variant="link"
          fontSize="sm"
          fontWeight="normal"
          color="gray.800"
          onClick={onClearFilters}
        >
          {t('please-clear-filters')}
        </Button>
        .
      </Text>
    </Box>
  )
}

export const Search = ({
  properties,
  allProperties,
  settings,
  engineId,
  sort,
  sortSeed
}) => {
  const { seo, searchCriteria = {} } = usePageContext()
  const { query } = useRouter()
  const isMobile = useBreakpointValue({ base: true, md: false })
  const [showMap, setShowMap] = useBoolean(false)
  const fullScreenMap = isMobile && showMap
  const { demo: isDemo } = settings
  const { t } = useTranslation('search')
  const [favoriteIds, setFavoriteIds] = useState()
  const { favoritesProperties, setFavoritesProperties } = useSignalContext()

  useEffect(() => {
    setFavoriteIds(JSON.parse(localStorage.getItem('favoriteIds') || '[]'))
  }, [])

  const { data, size, setSize, isValidating } = useSWRInfinite(
    (idx, previous) => getSWRKey(idx, previous, query),
    (_key, query, pageIndex) => {
      const { category, checkin, checkout, flexible, ...searchParams } = query

      // TODO: Figure out a way to prevent client-side first page fetch
      return api.searchProperties(engineId, {
        ...omit(searchParams, 'engineId'),
        categories_by_slug: category,
        checkin: checkin && checkout ? checkin : undefined,
        checkout: checkin && checkout ? checkout : undefined,
        ...(flexible ? { extra: 'flexible_stays' } : {}),
        sort,
        sort_seed: sortSeed,
        page: pageIndex
      })
    },
    {
      fallbackData: [properties],
      revalidateFirstPage: false
    }
  )
  const meta = useSWRInfiniteMeta({ data, size, isValidating })
  const propertiesResults = [].concat(...(data ?? []).map((d) => d.results))
  const displayCardList = !isMobile || (isMobile && !showMap)
  const searchHeaderTitle =
    meta.totalCount > 0
      ? `${propertiesResults.length} / ${meta.totalCount} ${t('properties')}`
      : `0 ${t('properties')}`

  return (
    <SettingContextProvider settings={settings}>
      <SearchContextProvider
        searchCriteria={searchCriteria}
        sortKey={settings.searchSortkey}
      >
        <Head>
          <title>{seo.page_title}</title>
          <meta name="description" content={seo.page_description} />
          <meta name="keywords" content={seo.page_keywords} />
        </Head>

        <Breadcrumbs className="block-hidden-on-mobile" />
        <SearchBar allProperties={allProperties} />
        <SearchOverlay />

        <Container
          maxW="container.2xl"
          px={fullScreenMap ? 0 : 5}
          className="search-page__wrapper"
          py={fullScreenMap ? 0 : 10}
        >
          {isMobile && (
            <MobileMapViewButton
              showMapView={showMap}
              onClick={setShowMap.toggle}
            />
          )}
          {!fullScreenMap && !isDemo && <PageSummary seo={seo} />}
          {!fullScreenMap && !!settings?.searchRateZeroMessage && (
            <ZeroRateMessage message={settings?.searchRateZeroMessage} />
          )}
          {isMobile && <FiltersTags />}

          <Flex gap="6">
            {displayCardList && (
              <Stack
                divider={<StackDivider />}
                spacing={2}
                flex="1"
                data-testid="search-property-list"
              >
                <PropertiesListHeader
                  titleText={searchHeaderTitle}
                  total={meta.totalCount}
                  showMap={showMap}
                  onShowMap={setShowMap.toggle}
                  isMobile={isMobile}
                />
                <PropertiesCardList
                  properties={propertiesResults}
                  size={size}
                  setSize={setSize}
                  isLoadingMore={meta.isLoadingMore}
                  isRefreshing={meta.isRefreshing}
                  hasMoreResults={meta.hasMoreResults}
                  showMap={showMap}
                  EmptyResultsComp={EmptyResults}
                  favoriteIds={favoriteIds}
                  setFavoriteIds={setFavoriteIds}
                  favoritesProperties={favoritesProperties}
                  setFavoritesProperties={setFavoritesProperties}
                />
              </Stack>
            )}
            {showMap && propertiesResults.length > 0 && (
              <PropertiesMap
                isFullScreen={fullScreenMap}
                properties={propertiesResults}
              />
            )}
          </Flex>
        </Container>
      </SearchContextProvider>
    </SettingContextProvider>
  )
}
