import { fitBounds, getBounds, Map } from '@/components/core/Map'
import { useSettingContext } from '@/contexts/settings'
import { useHideWordPressFooter } from '@/hooks/useHideWordPressFooter'
import { useTrackWordPressHeaderHeight } from '@/hooks/useTrackWordPressHeaderHeight'
import { Box, Popover, PopoverContent, PopoverTrigger } from '@chakra-ui/react'
import React from 'react'
import { useEvent, useIsomorphicLayoutEffect, useMeasure } from 'react-use'
import PropertyCard from './PropertyCard'

const getDefaultCenter = (properties) => {
  const property = properties[0]
  return property
    ? {
      lat: property.latitude,
      lng: property.longitude
    }
    : {
      lat: 0,
      lng: 0
    }
}

const PropertiesMap = ({
  properties,
  isFullScreen,
  nameKey = 'name',
  ...props
}) => {
  const ref = React.useRef(null)
  const [height, setHeight] = React.useState(0)
  const [width, setWidth] = React.useState(0)
  const [{ sticky, scrollOffset }, setSticky] = React.useState({})
  const [measureRef, { width: measureWidth }] = useMeasure()
  const [bounds, setBounds] = React.useState({
    center: getDefaultCenter(properties)
  })
  const { settings } = useSettingContext()

  // Track WordPress header height 🔨
  const [headerHeight, updateHeaderHeight] = useTrackWordPressHeaderHeight()

  // Hide WordPress footer on mobile view 🔨
  useHideWordPressFooter(isFullScreen || sticky)

  const onScroll = React.useCallback(() => {
    // make sure to update the header height on scroll
    const headerHeight = updateHeaderHeight()
    const map = ref.current

    // If the map is not sticky but we've scrolled past the WP header make it sticky
    if (!sticky && map.offsetTop - headerHeight - window.scrollY <= 0) {
      setSticky({ sticky: true, scrollOffset: window.scrollY })
    }

    // remove the sticky if we scroll back
    if (sticky && window.scrollY <= scrollOffset) {
      setSticky({ sticky: false })
    }
  }, [updateHeaderHeight, sticky, scrollOffset])

  // Listen to scroll events on the browser
  useEvent('scroll', onScroll, undefined, { capture: true })

  // Get the actual map width since we set it to 100%
  useIsomorphicLayoutEffect(() => {
    if (ref.current) {
      setWidth(ref.current.clientWidth)
    }
  }, [])

  // Update width when container width changes
  useIsomorphicLayoutEffect(() => {
    if (width !== measureWidth) {
      setWidth(measureWidth)
    }
  }, [measureWidth, width])

  // Update header height whenever width changes
  useIsomorphicLayoutEffect(() => {
    updateHeaderHeight()
  }, [measureWidth, updateHeaderHeight])

  // Update map height
  useIsomorphicLayoutEffect(() => {
    if (isFullScreen) {
      setHeight(window.innerHeight - ref.current.offsetTop)
    } else {
      setHeight(window.innerHeight - headerHeight)
    }
  }, [isFullScreen, headerHeight])

  // Calculate map bounds
  useIsomorphicLayoutEffect(() => {
    const bounds = getBounds(
      properties.map((p) => ({ lat: p.latitude, lng: p.longitude }))
    )

    if (bounds.nw.lat == bounds.se.lat && bounds.nw.lng === bounds.se.lng) {
      bounds.se.lat += 0.0001
      bounds.se.lng += 0.0001
    }

    setBounds(fitBounds(bounds, { height, width }))
  }, [width, height, properties])

  const stickyProps = sticky
    ? {
      position: 'fixed',
      bottom: 0,
      width
    }
    : {}

  return (
    <Box ref={measureRef} flex="1">
      <Map
        ref={ref}
        bounds={bounds}
        height={height}
        width="full"
        zoomSetting={settings?.mapZoom?.search}
        mapType={settings?.propertyMapView}
        {...stickyProps}
        {...props}
      >
        {properties.map((p, idx) => (
          <PopoverMarker
            key={idx}
            lat={p.latitude}
            lng={p.longitude}
            property={p}
            nameKey={nameKey}
          />
        ))}
      </Map>
    </Box>
  )
}

const PopoverMarker = ({ property, nameKey }) => {
  const [isSelected, setSelected] = React.useState()

  return (
    <Popover
      placement="top"
      onOpen={() => setSelected(true)}
      onClose={() => setSelected(false)}
      isLazy
    >
      <PopoverTrigger>
        <Map.PinMarker
          name={property[nameKey]}
          color={isSelected ? 'gray.800' : 'wpMain.primary'}
        />
      </PopoverTrigger>
      <PopoverContent boxShadow="lg" borderRadius="md" borderColor="gray.800">
        <PropertyCard
          property={property}
          onMouseDown={(e) => e.stopPropagation()}
          onDoubleClick={(e) => e.stopPropagation()}
          cursor="default"
        />
      </PopoverContent>
    </Popover>
  )
}

export default PropertiesMap
