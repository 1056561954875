import axios from 'axios'
import getConfig from 'next/config'
import { createBaseApiClient, getBaseApi, getBeyondUrl } from '../baseApi'
export default axios

const { publicRuntimeConfig } = getConfig()

export const getApi = (ctx) => {
  const axiosInstance = axios.create({
    baseURL: getBeyondUrl(publicRuntimeConfig.signalBeyondAppUrl)
  })
  const apiClient = createBaseApiClient(axiosInstance, ctx)
  return getBaseApi(apiClient, ctx)
}
