import { usePageContext } from '@/contexts/page'
import { useSearchContext } from '@/contexts/search'
import {
  Button,
  Flex,
  Spacer,
  Tag,
  TagCloseButton,
  TagLabel
} from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'
import { useMemo } from 'react'

const FiltersTags = () => {
  const { categories, setCategories, customgroups, setCustomgroups, onSearch } =
    useSearchContext()
  const { searchCriteria = {} } = usePageContext()
  const { t } = useTranslation('search')

  const setTagsAndSearch = (selectedTags) => {
    const selectedCategories = selectedTags
      .filter((tag) => tag.type === 'category')
      .map((t) => t.id)
    const selectedCustomgroups = selectedTags
      .filter((tag) => tag.type === 'customgroup')
      .map((t) => t.id)

    setCategories(selectedCategories)
    setCustomgroups(selectedCustomgroups)
    onSearch({
      categories: selectedCategories,
      customgroups: selectedCustomgroups
    })
  }

  const tags = useMemo(() => {
    if (categories.length === 0 && customgroups.length === 0) {
      return []
    }

    const mappedCategories = categories?.map((categoryId) => {
      const category = searchCriteria.categories.find(
        (category) => category.id === Number(categoryId)
      )
      return {
        id: categoryId,
        name: category.display_text || category.category_name,
        type: 'category'
      }
    })

    const mappedCustomgroups = customgroups?.map((customgroupId) => {
      const customgroup = searchCriteria.customgroups.find(
        (customgroup) => customgroup.id === Number(customgroupId)
      )
      return {
        id: customgroupId,
        name: customgroup.customgroup_name,
        type: 'customgroup'
      }
    })

    return [...mappedCategories, ...mappedCustomgroups]
  }, [
    searchCriteria.categories,
    categories,
    searchCriteria.customgroups,
    customgroups
  ])

  if (categories.length === 0 && customgroups.length === 0) {
    return <Spacer />
  }

  return (
    <Flex justifyContent={'space-between'} flex="1">
      <Flex gap="8px" wrap={'wrap'}>
        {tags.map((tag) => {
          return (
            <Tag
              size={'sm'}
              key={tag.id}
              borderRadius="5px"
              variant="solid"
              bgColor="gray.200"
              borderColor="gray.200"
              px="12px"
              py="4px"
              color="gray.800"
            >
              <TagLabel>{tag.name}</TagLabel>
              <TagCloseButton
                onClick={() =>
                  setTagsAndSearch(tags.filter((t) => tag.id !== t.id))
                }
              />
            </Tag>
          )
        })}
      </Flex>
      <Flex>
        <Button variant="ghost" onClick={() => setTagsAndSearch([])}>
          {t('clear-filters')}
        </Button>
      </Flex>
    </Flex>
  )
}

export default FiltersTags
