import React from 'react'

export const useSWRInfiniteMeta = ({ data, size, isValidating }) => {
  // Compute this outside memo so it gets recomputed if the size of the array changes
  const dataLength = data?.length

  // We need to derive this information since useSWR doesn't provide it
  return React.useMemo(() => {
    const hasMoreResults = data && !!data[dataLength - 1]?.next
    const totalCount = data?.[0]?.count
    const result = [].concat(...(data ?? []).map((d) => d.results))
    const isLoadingMore =
      data && typeof data[size - 1] === 'undefined' && hasMoreResults
    const isRefreshing = isValidating && dataLength === size
    return {
      result,
      isLoadingMore,
      isRefreshing,
      hasMoreResults,
      totalCount
    }
  }, [data, dataLength, isValidating, size])
}
